




import Vue from 'vue';

export default Vue.extend({
  name: 'BoardEdit',

  props: {
    id: { type: String, required: true },
  },

  mounted() {
    this.$bus.$emit('title-change', 'Boards', '/boards');
  },
});
