




import Vue from 'vue';

export default Vue.extend({
  name: 'BoardCreate',

  mounted() {
    this.$bus.$emit('title-change', 'Boards', '/boards');
  },
});
