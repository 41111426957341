






























































import Vue from 'vue';
import BoardsList from '../components/BoardsList.vue';
import { db } from '@/firebase';
import { BoardSessions } from '../models';

export default Vue.extend({
  name: 'BoardsList',

  components: { BoardsList },

  data() {
    return {
      draftBoards: [] as BoardSessions,
      activeBoards: [] as BoardSessions,
      closedBoards: [] as BoardSessions,
    };
  },

  computed: {
    userId(): string {
      return this.$store.getters['user/userId'];
    },
  },

  created() {
    const boardsRef = db.collection('boards').where('owner', '==', this.userId);
    this.$bind('draftBoards', boardsRef.where('status', '==', 'Draft').orderBy('createdOn', 'desc'));
    this.$bind('activeBoards', boardsRef.where('status', '==', 'Active').orderBy('createdOn', 'desc'));
    this.$bind('closedBoards', boardsRef.where('status', '==', 'Closed').orderBy('createdOn', 'desc'));
  },

  mounted() {
    this.$bus.$emit('title-change', 'Boards', '/boards');
  },
});
